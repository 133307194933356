import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import SingleReview from './pages/SingleReview';
import ErrorPage from './pages/ErrorPage';
import Interview from './pages/Interview';
import Bleak from './pages/Bleak';
import EndOfAlbion from './pages/Albion';
import ScrollToTop from './ScrollToTop';
import './App.css';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/review/i-wont-be-by-your-side" element={<SingleReview />} />
          <Route path="/artist-info" element={<ErrorPage />} />
          <Route path="/interview" element={<Interview />} />
          <Route path="/albion" element={<EndOfAlbion />} />
          <Route path="/bleak" element={<Bleak />} />
          <Route path="/merch" element={<ErrorPage />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
