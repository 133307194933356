import React from 'react';
import { Link } from 'react-router-dom';

const Interview = () => {
    return (
        <div className="interview-container" style={{ padding: '20px', maxWidth: '800px', margin: '0 auto', fontFamily: 'Arial, sans-serif', color: '#333' }}>
            <h2>In Conversation with Matthew Pontius Semble</h2>
            <h3>Lead Guitarist for Family Reunion</h3>
            <p>Date Posted: 3/12/2002</p>
            
            <h3>Starting Out in Gary, Indiana</h3>
            <p><strong>MRF:</strong> Matt, you hail from Gary, Indiana — a place that one might describe as the modern-day crucible of the American Rust Belt. How do you think growing up in such an environment, amidst the decaying remnants of industrial ambition, has shaped your artistic ethos?</p>
            <p><strong>Semble:</strong> Gary’s a tough place, no doubt about it. It’s rough around the edges, and it doesn’t let you forget it. For me, it was about survival. Music became the way out, even if I didn’t realize it at the time. The steel mills, the grit, all of it — yeah, it seeps into you. But I wouldn’t say I had an artistic “ethos” back then. It was more like a desperate need to carve out something that made sense. Punk was that for me, at first. It was raw, angry — just like the world I saw around me. Music wasn’t about some grand vision; it was about making noise that felt real when everything else felt like it was falling apart and people in positions of power were telling you everything's fine. You needed something to cut through the bullshit.</p>
            
            <h3>From Punk to Shoegaze</h3>
            <p><strong>MRF:</strong> Transitioning from the raw, unbridled energy of punk to the layered, atmospheric depths of shoegaze could be likened to a philosophical shift — from the visceral immediacy of existential rebellion to a more introspective contemplation of the sublime. How did you navigate that transformation?</p>
            <p><strong>Semble:</strong> Well, I wouldn’t put it quite like that. Heh, you are some kinda weirdo smart guy, aren't you? For me, it wasn’t some deep, philosophical thing — it was just about finding a new way to express what I was feeling. Punk gave me the energy, the anger, but I started craving something more. And it was really just circumstance, honestly. Because I met Jim and he was the shoegaze guy, or at least his reputation kind of became that. Family Reunion had this edge to their sound that drew me in. To me, it wasn't all that different from what I was doing before. It was like taking everything I loved about punk and throwing it into a blender with a bunch of reverb and fuzz. It just clicked for me.</p>
            
            <h3>Defining the Sound of Family Reunion</h3>
            <p><strong>MRF:</strong> Your relationship with your Stratocaster has been described as almost symbiotic, a kind of ontological extension of your very being. To what extent do you feel that the instrument is integral to the sonic identity you’ve helped craft for Family Reunion?</p>
            <p><strong>Semble:</strong> Jesus, you should be in a room with a bunch of chalkboards going calculations or something.</p>
            <p><strong>MRF:</strong> I assure you, I am actually quite terrible at maths.</p>
            <p><strong>Semble:</strong> The Strat’s my sound. Simple as that. When Jimmy suggested I switch to a Gibson, I nearly lost it. That fucker was really on a Gibson kick and it honestly kind of unnerved me. The Strat isn’t just some tool — it’s how I make my mark, you know? It’s got this bite, this clarity that nothing else has. It’s the only guitar that could give me the sound I needed to make Family Reunion what it was. Jim eventually got it, but yeah, that was a fight. The Strat stayed, and it’s still with me, wherever I go.</p>

            <h3>The Shadows of the Past</h3>
            <p><strong>MRF:</strong> The concept of legal entanglements and the subsequent existential consequences they impose brings to mind the notion of the hero’s journey through the underworld — a descent into darkness that ultimately forges a new, albeit scarred, identity. How have your own experiences with the law informed your creative process?</p>
            <p><strong>Semble:</strong> You can call it whatever you want, but getting locked up was a reality check, plain and simple. I was young, pissed off, and made some dumb choices. Ended up doing time for it. That changes you. But, look, I'm not one of these guys that's going around to high schools trying to scare today's miscreants into becoming law abiding citizens, or anything like that. My experiences also fueled the fire. When you’re stuck in a cell, with nothing but your thoughts and anger, it shapes how you play, how you write. That rage doesn’t just go away — it never goes away. It stays with you everywhere you go. Sometimes I wake up and for a second there, I still feel like I'm in a cell. It finds a way into everything you do. Music was just my way of dealing with it, of getting that anger out without losing myself completely.</p>

            <h3>Moving to the Business Side</h3>
            <p><strong>MRF:</strong> Shifting from the creative chaos of making music to the structured machinations of the music industry might be seen as a dialectical reversal, where the artist becomes the curator. How did you reconcile these seemingly opposing roles?</p>
            <p><strong>Semble:</strong> Honestly, it wasn’t that complicated for me. The band ended, but I wasn’t done with music. Lurch Records had been with us from the start, and they offered me a way to stick around. Turns out, I was good at it—helping other artists find their sound, their path. It’s still creative work, just in a different way. I get to take what I learned on the road and use it to help other musicians avoid the pitfalls. It’s fulfilling, even if it’s not what I imagined I’d be doing.</p>

            <h3>The Disappearance of James Rose</h3>
            <p><strong>MRF:</strong> Rose's disappearance in 1998 has become a mythologized enigma in the annals of modern music history, akin to the vanishing acts of certain literary figures who retreat into the ether of their own creations. Having been so close to him, what’s your take on what might have happened, and how has that absence reverberated in your life?</p>
            <p><strong>Semble:</strong> Jim was always a bit of a mystery, even to us. He had his demons, sure, but no one expected him to just up and disappear on us. It’s like one day he was there, and the next—gone. I’ve thought about it a lot, and I think maybe he just couldn’t handle it anymore—the pressure, the constant need to create something better than before. Maybe he found peace in disappearing, or maybe he just needed to get away. I miss him, though. Every time I pick up the guitar, I think of him. There’s a void there that’ll never be filled.</p>
            <p><strong>MRF:</strong> That’s heavy. It must have been a lot to process, especially with the band at its peak. Did you see any signs that he was struggling that much?</p>
            <p><strong>Semble:</strong> Looking back, yeah, there were definitely signs. Like, red flags, even. Honestly, I think his brain was on fire or something. Some people are just like that. He was always a bit distant, but in those last few months, it was like he wasn’t really there. He’d show up to practice, but you could tell his mind was somewhere else. We were all so caught up in the band’s relative success that we didn’t push him on it. I kinda wish we had now. I mean, I've said that before. He was all caught up in that Roshan shit. You know? Maybe we should have had some kind of intervention or something. Maybe things would have turned out differently, but it’s hard to say. Honestly, I think Jim was always gonna do what he was gonna do. You really couldn't tell him anything.</p>

            <h3>Life Lessons and Advice</h3>
            <p><strong>MRF:</strong> Having traversed both the creative and administrative spheres of the music world, what pearls of wisdom would you offer to those embarking on their own musical odyssey?</p>
            <p><strong>Semble:</strong> Stay true to yourself, and don’t let anyone tell you who to be. The industry can be brutal, horrifically brutal, in fact, but if you keep your focus on what matters — your sound, your vision — you’ll find a way through. And if you’re on the business side, remember it’s the artists who make it all happen. Support them, don’t stifle them. And yeah, maybe don’t suggest your lead guitarist switch guitars unless you’re ready for a fight. Heh.</p>

            <p><Link to="/">Back to Home</Link></p>
        </div>
    );
};

export default Interview;
